import { observer } from "mobx-react";
import StoreContext from "../../store/RootStore";
import { toJS } from "mobx"; // Import toJS from MobX

import ContentBox from "../../components/contentBox";
import AssetsSlider from "../../components/assetsSlider";
import { SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "./index.sass";
import "../Raid_Menu/index.sass";
import { useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";
import STimer from "../../components/stimer";
import Preloader from "../../components/preloader";
import { toast } from "react-toastify";

function Staking() {
  const { AccountStore, FunctionStore } = StoreContext();
  const [selectedtype, setSelectedtype] = useState("staked");
  const [rarityValue, setRarityValue] = useState("All");
  const [selectedAssets, setSelectedAssets] = useState(
    AccountStore.stakingAssets
  );
  const [selectedToken, setSelectedToken] = useState("RADS");

  const toggleToken = () => {
    if (selectedToken === "RADS") {
      setSelectedToken("WUF");
    } else {
      setSelectedToken("RADS");
    }
  };

  useEffect(() => {
    console.log("selectedToken", selectedToken);
    console.log("stakingAssets", AccountStore.getStakingAssets());
    console.log("selectedAssets", selectedAssets);
    if (selectedToken === "RADS") {
      setSelectedAssets(
        AccountStore.getStakingAssets()?.filter((a) => !a.config)
      );
    } else if (selectedToken === "WUF") {
      const wufMiners = toJS(AccountStore.getWufMiners()); // Convert proxy to plain object
      console.log("getWufMiners", wufMiners, wufMiners.length);
      setSelectedAssets(wufMiners);
    }
  }, [AccountStore.stakingAssets, selectedToken]);

  function getRemaingTime(last_claim) {
    var endTime = parseInt(last_claim) + 86400;
    var current = new Date().getTime() / 1000;
    if (selectedToken === "WUF") {
      if (parseInt(current) > 1728666000) {
        current = 1728666000;
      }
    }
    var total = parseInt(endTime) - current;
    var days = Math.floor(total / 86400);
    var hours = Math.floor((total % 86400) / 3600);
    var minutes = Math.floor((total % 3600) / 60);
    var seconds = Math.floor(total % 60);
    return [days, hours, minutes, seconds];
  }

  function getClaimable() {
    var allAssets = selectedAssets?.filter((a) => a.staked);
    var FinalClaimable = 0.0;
    for (var i = 0; i < allAssets.length; i++) {
      var asset = allAssets[i];
      var rTime = getRemaingTime(asset.last_claim);
      var days = parseFloat(rTime[0]);
      if (days > 0) continue;
      else days *= -1;
      if (selectedToken === "WUF" && days > 3) days = 3;
      var rate = parseFloat(asset.rate);
      FinalClaimable += rate * days;
    }
    return FinalClaimable + " " + selectedToken;
  }

  function checkClaimable(last_claim) {
    var last_time = parseInt(last_claim);
    var current_time = new Date().getTime() / 1000;
    var diff = current_time - last_time;
    if (diff >= 86400) return true;
    else return false;
  }

  const handleChange2 = (event) => {
    setRarityValue(event.target.value);
  };

  function SortNfts() {
    var nfts = selectedAssets?.filter(
      (a) => a.staked == (selectedtype == "staked")
    );

    if (
      rarityValue != "All" &&
      rarityValue != "cryptodeadnw" &&
      rarityValue != "talesofcrypt"
    ) {
      nfts = nfts.filter((a) => a.rarity == rarityValue);
    }
    if (rarityValue == "cryptodeadnw") {
      nfts = nfts.filter((a) => a.collection.collection_name == "cryptodeadnw");
    }
    if (rarityValue == "talesofcrypt") {
      nfts = nfts.filter((a) => a.collection.collection_name == "talesofcrypt");
    }

    return nfts;
  }

  async function claimnfts(assets) {
    if (selectedToken === "RADS") {
      await FunctionStore.claimStaking(assets);
    } else if (selectedToken === "WUF") {
      await FunctionStore.claimMiners(assets);
    }
  }

  async function stakeNFTAction(assets) {
    if (selectedToken === "RADS") {
      await FunctionStore.stakeNFT(assets);
    } else if (selectedToken === "WUF") {
      await FunctionStore.stakeMiner(assets);
    } else toast.error("Please select a token to stake");
  }

  function getWufPool() {
    var pool_data = AccountStore.getWufPools();
    console.log("pool_data", AccountStore.getWufPools());
    var total_pool = pool_data?.find((a) => a.type == "wufminer")?.total_pool;
    var numberToString = "";
    var parsedNumber = parseFloat(total_pool)?.toFixed(2);
    //if total_pool is 600,000,000 return 600M
    if (parsedNumber >= 1000000000) {
      numberToString = (parsedNumber / 1000000000).toFixed(2) + "B";
    } else if (parsedNumber >= 1000000) {
      numberToString = (parsedNumber / 1000000).toFixed(0) + "M";
    } else {
      numberToString = parsedNumber;
    }
    return numberToString + " WUF";
  }

  function getRemainingDays(last_claim) {
    var pool_data = AccountStore.getWufPools();
    console.log("pool_data", AccountStore.getWufPools());
    var end_time = pool_data?.find((a) => a.type == "wufminer")?.end_time;
    var start_time = pool_data?.find((a) => a.type == "wufminer")?.start_time;
    var current_time = new Date().getTime() / 1000;
    var diff = end_time - current_time;
    var days = Math.floor(diff / 86400);
    return days;
    //
  }

  return (
    <div className="menu">
      <ContentBox title="Staking">
        <div className="asset-inventory-content">
          <div className="asset-inventory-content-buttons">
            <button
              className="statistics-button"
              onClick={() => {
                setSelectedtype("unstaked");
              }}
            >
              UNSTAKED ({selectedAssets?.filter((a) => !a.staked).length})
            </button>
            <>
              <button
                className="statistics-button"
                onClick={() => {
                  setSelectedtype("staked");
                }}
              >
                STAKED ({selectedAssets?.filter((a) => a.staked).length})
              </button>
            </>
            {selectedtype == "unstaked" &&
              selectedAssets?.filter((a) => !a.staked).length > 0 && (
                <button
                  className="statistics-button3"
                  onClick={() => {
                    setSelectedtype("unstaked");
                    stakeNFTAction(SortNfts().map((a) => a.asset_id));
                  }}
                >
                  STAKE ALL
                </button>
              )}
            {selectedAssets?.filter(
              (a) => a.staked && checkClaimable(a.last_claim)
            ).length > 0 && (
              <button
                className="statistics-button3"
                onClick={() => {
                  setSelectedtype("staked");
                  claimnfts(
                    selectedAssets
                      ?.filter((a) => a.staked && checkClaimable(a.last_claim))
                      .map((a) => a.asset_id)
                  );
                }}
              >
                CLAIM {" <"}
                {
                  selectedAssets?.filter(
                    (a) => a.last_claim && checkClaimable(a.last_claim)
                  ).length
                }
                {">"}
              </button>
            )}
            <div className="statistics-">
              <select
                value={rarityValue}
                onChange={handleChange2}
                className="statistics-optiontext"
              >
                <option value="All" className="statistics-button2">
                  All
                </option>
                <option value="Common" className="statistics-button2">
                  Common
                </option>
                <option value="Uncommon" className="statistics-button2">
                  Uncommon
                </option>
                <option value="Rare" className="statistics-button2">
                  Rare
                </option>
                <option value="Epic" className="statistics-button2">
                  Epic
                </option>
                <option value="Legendary" className="statistics-button2">
                  Legendary
                </option>
                <option value="Mythic" className="statistics-button2">
                  Mythic
                </option>
                <option value="crytpodeadnw" className="statistics-button2">
                  crytpodeadnw \
                  {
                    selectedAssets?.filter(
                      (a) =>
                        a.collection.collection_name == "cryptodeadnw" &&
                        a.staked == (selectedtype == "staked") &&
                        a.rate
                    ).length
                  }
                  /
                </option>
                <option value="talesofcrypt" className="statistics-button2">
                  talesofcrypt \
                  {
                    selectedAssets?.filter(
                      (a) =>
                        a.collection.collection_name == "talesofcrypt" &&
                        a.staked == (selectedtype == "staked")
                    ).length
                  }
                  /
                </option>
              </select>
            </div>
          </div>
          <div className="asset-inventory-content-slider">
            {AccountStore.loading ? (
              <Preloader />
            ) : (
              <>
                <div className="asset-inventory-content-tabs">
                  <div className="asset-inventory-content-tab">
                    <h6 style={{ color: "white" }}>Production: </h6>
                    <span style={{ color: "yellow" }}>
                      {selectedAssets
                        ?.filter((a) => a.staked && a.rate)
                        .reduce((a, b) => a + parseFloat(b.rate), 0)
                        .toFixed(2)}{" "}
                      {selectedToken}/DAY
                    </span>
                  </div>
                  <div className="asset-inventory-content-tab">
                    <h6 style={{ color: "white" }}>
                      Claimable:
                      <span style={{ color: "green" }}>{getClaimable()}</span>
                    </h6>
                  </div>
                  {selectedToken === "WUF" && (
                    <div className="asset-inventory-content-tab">
                      <h6 style={{ color: "white" }}>Pool: </h6>
                      <span style={{ color: "yellow" }}>{getWufPool()} </span>
                      {", "}
                      <span style={{ color: "red" }}>
                        {getRemainingDays()} DAYS LEFT
                      </span>
                    </div>
                  )}
                  <div
                    className="asset-inventory-content-tab"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => toggleToken()}
                  >
                    <h6
                      style={{
                        backgroundSize: "cover",
                        backgroundColor: "transparent",
                        color: "white",
                        padding: "5px",
                        borderRadius: "10px",
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        fontFamily: "game-over",
                        fontSize: "2vh",
                      }}
                    >
                      SWITCH TO
                      {selectedToken == "RADS" ? " WUF" : " RADS"}
                    </h6>
                    {selectedToken !== "RADS" ? (
                      <img
                        src={require("../../assets/toc/rads.png")}
                        alt="rads"
                        style={{ height: "5vh" }}
                      />
                    ) : (
                      <img
                        src="https://wax.alcor.exchange/_nuxt/img/wuf_wuffi.40c3bd1.png"
                        alt="wuf"
                        style={{ height: "5vh" }}
                      />
                    )}
                  </div>
                </div>
                <AssetsSlider>
                  {selectedAssets?.filter(
                    (a) => a.staked == (selectedtype == "staked")
                  ).length > 0 ? (
                    SortNfts().map((asset) => {
                      return (
                        <SwiperSlide key={asset.asset_id}>
                          <div className="assets-slider-item">
                            <h4 className="assets-slider-item-title">
                              {asset.name}
                            </h4>
                            <h4 className="assets-slider-item-sub">
                              {parseFloat(asset.rate) + " " + selectedToken}{" "}
                              /DAY{" "}
                              {asset.template.immutable_data.Rarity
                                ? asset.template.immutable_data.Rarity
                                : ""}{" "}
                            </h4>
                            <a
                              style={{
                                color: "yellow",
                                padding: "5px",
                              }}
                              href={`https://wax.atomichub.io/explorer/asset/${asset.asset_id}`}
                              target="_blank"
                            >
                              {"# " + asset.asset_id}
                            </a>
                            <h4 className="assets-slider-item-sub">
                              {asset.staked &&
                              !checkClaimable(asset.last_claim) ? (
                                <STimer
                                  endTime={
                                    new Date(
                                      (parseInt(asset?.last_claim) + 86400) *
                                        1000
                                    )
                                  }
                                />
                              ) : asset.staked ? (
                                <button
                                  className="assets-slider-item-button"
                                  onClick={() => {
                                    claimnfts([asset.asset_id]);
                                  }}
                                >
                                  CLAIM
                                </button>
                              ) : (
                                <></>
                              )}
                            </h4>
                            <div className="assets-slider-item-image-wrapper">
                              {asset.template.immutable_data.video &&
                              !AccountStore.getMode() ? (
                                <video
                                  src={`https://atomichub-ipfs.com/ipfs/${asset.template.immutable_data.video}`}
                                  className={`assets-slider-item-image`}
                                  alt={``}
                                  autoPlay
                                  loop
                                  muted
                                />
                              ) : (
                                <img
                                  src={
                                    asset.template.immutable_data.img
                                      ? `https://atomichub-ipfs.com/ipfs/${asset.template.immutable_data.img}`
                                      : asset.template.immutable_data.imgback
                                      ? `https://atomichub-ipfs.com/ipfs/${asset.template.immutable_data.imgback}`
                                      : `https://atomichub-ipfs.com/ipfs/${asset.template.immutable_data["img back"]}`
                                  }
                                  className={`assets-slider-item-image`}
                                  alt={``}
                                />
                              )}
                            </div>
                            <div className="assets-slider-item-buttons">
                              {!asset.staked ? (
                                <button
                                  className="assets-slider-item-button"
                                  onClick={() => {
                                    stakeNFTAction([asset.asset_id]);
                                  }}
                                >
                                  STAKE
                                </button>
                              ) : (
                                <button
                                  className="assets-slider-item-button"
                                  onClick={() => {
                                    FunctionStore.unstakeNFT([asset.asset_id]);
                                  }}
                                >
                                  UNSTAKE
                                </button>
                              )}
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })
                  ) : (
                    <h6
                      style={{
                        color: "white",
                        textAlign: "center",
                        width: "100%",
                        fontSize: "3vh",
                        margin: "5vh",
                        fontFamily: "game-over",
                      }}
                    >
                      No assets
                    </h6>
                  )}
                </AssetsSlider>
              </>
            )}
          </div>
        </div>
      </ContentBox>
    </div>
  );
}

export default observer(Staking);
